.StickersAdvice {
    width: 100%;
    padding: 0 16px;
    
    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &--title {
            color: var(--trashie-ui-faded-black-100);
            font-family: "GT Maru";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            text-transform: uppercase;
            margin-bottom: 8px;
        }
        
        &--animation {
            width: 100%;
            height: 220px;
            border: none;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--content {
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            &--text {
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;

                ul {
                    list-style-type: disc;
                    padding-left: 20px;
                    margin: 0;
                }
            }

            &--actions {
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin-top: 8px;

                .MuiFormControlLabel-label {
                    color: var(--trashie-ui-faded-black-100) !important;
                    font-family: "GT Maru";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }
            }

            &--advice {
                display: flex;
                padding-top: 24px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                align-self: stretch;
                gap: 8px;
                color: var(--trashie-ui-faded-black-100);
                font-family: "GT Maru";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;

                &--link {
                    color: var(--trashie-primary-code-blue);
                    font-family: "GT Maru";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%;
                    text-transform: uppercase;
                }
            }
        }
    }
}
