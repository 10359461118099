.RegisterPayment {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
        padding: 16px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--trashie-ui-faded-black-30);
        background: var(--trashie-ui-crisp-white);
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);

        &--title {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            &-text {
                color: var(--trashie-ui-faded-black-100);
                font-size: 14px;
                font-weight: 500;
                line-height: 150%;
            }

            &-strike {
                color: var(--trashie-primary-rich-red);
                text-decoration: line-through;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
                margin-right: 2px;
            }

            &-value {
                color: var(--trashie-ui-faded-black-100);
                font-size: 18px;
                font-weight: 700;
                line-height: 150%;
            }
        }

        &--content {
            display: flex;
            flex-direction: column;
            align-items: stretch;

            &-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
                padding: 16px 8px;
                color: var(--trashie-ui-faded-black-100);
                font-size: 14px;
                font-weight: 700;
                line-height: 150%;

                &:not(:last-child) {
                    border-bottom: 1px solid var(--trashie-ui-faded-black-30);
                }

                &-icons {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    gap: 0;
                    align-self: stretch;
                    margin-left: 9px;

                    >* {
                        margin-left: -9px;
                        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.09);
                    }

                    &-icon {
                        margin-left: -18px;
                    }
                }

                &-cards {
                    width: 55px;
                    height: 49px;
                    position: relative;

                    >* {
                        width: 42px;
                        position: absolute;
                        border-radius: 1.5px;

                        &:nth-child(1) {
                            bottom: 7px;
                            right: 8px;
                            transform: rotate(8deg);
                        }

                        &:nth-child(2) {
                            bottom: 8px;
                            right: 7px;
                            transform: rotate(28deg);
                        }

                        &:nth-child(3) {
                            bottom: 10px;
                            right: 3px;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }

    &__embeddedCheckout {
        width: 100%;
    }
}