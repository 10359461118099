.ShippingProviderSelectItem {
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 16px;
    border-radius: 8px;
    background: var(--trashie-ui-crisp-white);
    border: 1px solid var(--trashie-ui-faded-black-30);
    width: 100%;

    &.selectedCardContainer {
        border-radius: 8px;
        border: 2px solid var(--trashie-primary-code-blue);
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);

        &:hover {
            border: 2px solid var(--trashie-primary-code-blue);
        }
    }

    &__collapsable {
        button {
            color: var(--trashie-ui-faded-black-60);
            justify-content: unset;
            gap: 4px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%
        }
    }

    &__input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-height: 100px;
        padding: 20px 0px 20px 16px
    }

    &__markdown {
        p {
            margin: 0px;
        }
    }

    &__shipping-details {
        padding: 20px 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1 1 auto;
    }

    &__img {
        background-color: var(--trashie-primary-thrifty-blue);
        width: 25%;
        padding: 10px;
        min-width: 96px;
    }

    &__img-storeUrl {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        align-self: stretch;
        gap: 12px;



        .shippingProviderDescriptionContainer {
            display: flex;
            flex-direction: column;
            width: 100%;

            .shipingProviderNameContainer {
                display: flex;
                flex-direction: row;
                gap: 8px;
                justify-items: center;

                .shipingProviderNameText {
                    color: var(--trashie-ui-faded-black-100);
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 150%;
                    margin: 0;
                }

            }

            .shipingProviderPromotionText {
                color: var(--take-back-bags-lime);
                font-size: 12px;
                font-weight: 400;
                line-height: 150%;
                margin: 0;
            }
        }
    }

    &__details {
        color: var(--trashie-ui-faded-black-100);
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;
    }

    &__storesLink {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        color: var(--trashie-ui-faded-black-60);
        text-transform: capitalize;
    }
}

.shippingProviderImage {
    height: 100%;
    width: 100%;
    max-height: 96px;
    object-fit: contain;
}

.radioButton {
    margin-left: auto;
    appearance: none;
    height: 18px;
    width: 18px;
    min-width: 18px;
    border: 1px solid var(--trashie-ui-faded-black-100);
    border-radius: 50%;
    background-color: transparent;
    outline: none;
    cursor: pointer;

    &:not(:disabled):checked {
        background-clip: content-box;
        background-color: var(--trashie-primary-code-blue);
        padding: 10%;
        min-width: 18px;
    }

    &:disabled {
        border: 1px solid var(--trashie-ui-faded-black-60);
    }
}

.shipingProviderPromotionnNote {
    color: var(--trashie-ui-faded-black-80);
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 0;
}

@media (max-width: 370px) {
    .ShippingProviderSelectItem {
        &__collapsable {
            .CollapsableSection__title.small {
                font-size: 12px
            }
        }
    }
}

