.RegisterService {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    width: 100%;
    align-self: stretch;

    &__container {
        display: flex;
        flex: 1;
        align-items: stretch;
        flex-direction: column;
        gap: 32px;
        width: 100%;

        &--item {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 12px;

            &-header {
                display: flex;
                flex-direction: column;
                gap: 6px;

                &-title {
                    color: var(--trashie-ui-faded-black-100);
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 100%;
                }
    
                &-description {
                    color: var(--trashie-ui-faded-black-70);
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;
                }
            }

            &-content {
                display: flex;
                color: var(--trashie-ui-faded-black-40);
                font-size: 22px;
                font-weight: 500;
                gap: 8px;

                &-slider {
                    width: 100%;
                    overflow: hidden;

                    &-card {
                        height: 243px;
                    }
                }
            }
        }

        &--cta {
            display: flex;
            flex-direction: column;
            align-self: stretch;
            justify-self: flex-end;
            align-items: stretch;
            gap: 12px;
            padding: 16px 24px 24px;
            margin: 0 -16px;
            border-radius: 20px 20px 0 0;
            border-top: 1px solid var(--trashie-ui-faded-black-30);
            background-color: var(--trashie-ui-faded-black-10);
            box-shadow: 0px -159px 44px 0px rgba(0, 0, 0, 0.00), 0px -102px 41px 0px rgba(0, 0, 0, 0.01), 0px -57px 34px 0px rgba(0, 0, 0, 0.05), 0px -25px 25px 0px rgba(0, 0, 0, 0.09), 0px -6px 14px 0px rgba(0, 0, 0, 0.10);
            position: sticky;
            bottom: 0;
            z-index: 9;

            &-text {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 12px;

                &-label {
                    flex: 1;
                    color: var(--trashie-ui-faded-black-70);
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 150%;
                }

                &-value,
                &-strike {
                    color: var(--trashie-ui-faded-black-100);
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 150%;
                }

                &-strike {
                    color: var(--trashie-primary-rich-red);
                    text-decoration: line-through;
                }
            }
        }
    }

    .RegisterServiceContentItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        background-color: white;
        padding: 16px 50px 16px 16px;
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.03);
        width: 100%;
        min-height: 100px;
        position: relative;
        overflow: hidden;

        &__title {
            color: var(--trashie-ui-faded-black-70);
            font-size: 14px;
            font-weight: 400;
            line-height: 130%;
        }

        &__description {
            color: var(--trashie-ui-faded-black-100);
            font-size: 26px;
            font-weight: 500;
            line-height: 150%;
        }

        &__icon-container {
            position: absolute;
            right: -11px;
            top: -11px;
        }
    }
}

@media (max-width: 768px) {
    .RegisterService {    
        &__container {
            &--item {
                &-content {
                    overflow: hidden;
                }
            }
        }
    }
}
