.PayOnShipContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.PayOnShipVideo {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.PayOnShipWelcome {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 180px 32px 120px;
    margin: 0;
    box-sizing: border-box;

    &__image {
        display: flex;
        width: 290px;
        align-items: center;
        justify-content: center;
        margin: 32px 0;

        img {
            width: 100%;
            transform: rotate(-15deg);
        }
    }

    &__logo {
        display: flex;
        max-width: 90%;
        align-items: center;
        justify-content: center;
        margin-top: 0;

        img {
            width: 100%;
        }

        &.is-trashie {
            width: 180px
        }
    }
}

.payonship-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: auto;

    button {
        width: 60%;
        max-width: 300px;
        border-radius: 25px;
    }
}

@media only screen and (max-width: 480px) {
    .PayOnShipWelcome {
        padding: 120px 16px 80px;

        &__image {
            img {
                width: 80%;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .PayOnShipVideo video {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
    }
}